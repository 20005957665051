<template>
    <div class="page">
        <banner></banner>
        <div class="tuijian-wrap w-1300">
            <div class="center-text">
                <p></p>
                <span>{{name}}</span>
            </div>
            <div class="tuijian-list">
                <div class="tuijian-item" v-for="(item,index) in fenleiList" :key="index">
                    <div class="tuijian-top">
                        <div class="tuijian-text">
                            <div class="tuijian-p">{{item.name}}</div>
                            <div class="tuijian-p1">{{item.content}}</div>
                        </div>
                    </div>
                    <div class="tuijian-bottom">
                        <div class="tuijian-bt"  @click="goTo('msgs_list',{id:item.id})">查看分类</div>
                    </div>
                </div>
            </div>

            <div class="pingtai">
                <div class="pingtai-text">
                    <div class="pingtai-p">{{gg[0]?gg[0].title:''}}</div>
                    <div class="pingtai-p1">{{gg[0]?gg[0].content:''}}</div>
                </div>
                <div class="pingtai-list">
                    <div class="pingtai-item">
                        <img :src="gg[1]?gg[1].pic_url:require('../../../assets/images/kjdspt1.png')" alt="">
                        <div class="pingtai-item-p">{{gg[1]?gg[1].title:''}}</div>
                    </div>
                    <div class="pingtai-item">
                        <img :src="gg[2]?gg[2].pic_url:require('../../../assets/images/kjdspt2.png')" alt="">
                        <div class="pingtai-item-p">{{gg[2]?gg[2].title:''}}</div>
                    </div>
                    <div class="pingtai-item">
                        <img :src="gg[3]?gg[3].pic_url:require('../../../assets/images/kjdspt3.png')" alt="">
                        <div class="pingtai-item-p">{{gg[3]?gg[3].title:''}}</div>
                    </div>
                    <div class="pingtai-item">
                        <img :src="gg[4]?gg[4].pic_url:require('../../../assets/images/kjdspt4.png')" alt="">
                        <div class="pingtai-item-p">{{gg[4]?gg[4].title:''}}</div>
                    </div>
                    <div class="pingtai-item">
                        <img :src="gg[5]?gg[5].pic_url:require('../../../assets/images/kjdspt5.png')" alt="">
                        <div class="pingtai-item-p">{{gg[5]?gg[5].title:''}}</div>
                    </div>
                </div>
            </div>

        </div>
        <div class="zbcgbg"><img :src="gg[6]?gg[6].pic_url:require('../../../assets/images/zbcgbg1.png')" alt=""></div>

        <div class="xinxi-wrap w-1300">
            <div class="center-text">
                <p></p>
                <span>推荐信息</span>
            </div>
            <div class="xinxi-banner">
                <div class="swiper-container"  ref="xinxibanner">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide" v-for="(item,index) in tuijianliebiao" :key="index">
                            <div class="xinxi-img"><img :src="item.banner_url" alt=""></div>
                            <div class="xinxi-text">{{item.name}}</div>
                            <div class="xinxi-p" v-html="delLabel(item.content)"></div>
                            <div class="xinxi-bt"  @click="goTo('/msgs_details',{id:item.id})">查看详情</div>
                            <!-- <div class="xinxi-bt"  @click="goTo('/recom',{id:item.id})">查看详情</div>  -->
                        </div>
                    </div>                
                </div>
                <div class="swiper-button-next  swiper-button-black" ref="swiper-button-next"></div>
                <div class="swiper-button-prev  swiper-button-black" ref="swiper-button-prev"></div>
            </div>
        </div>

        <div class="zbcgbg"><img :src="gg[7]?gg[7].pic_url:require('../../../assets/images/zbcgbg2.png')" alt=""></div>

        <div class="mokuai-wrap w-1300">
            <div class="center-text">
                <p></p>
                <span>推荐模块</span>
            </div>
            <div class="mokuai-list">
                <div class="mokuai-item" v-for="(item,index) in tuijianfenlei" :key="index">
                    <div class="mokuai-text">{{item.name}}</div>
                    <div style="overflow:hidden;height:212px;">
                        <div class="mokuai-p" v-for="(item1,index1) in liebiaoList[item.id]" :key="index1">
                            <p>0{{index1+1}}</p>
                            <span v-html="delLabel(item1.content)"></span>
                        </div>
                    </div>
                    
                    <div class="more" @click="goTo('msgs_list',{id:item.id})">查看更多 <i class="el-icon-arrow-right"></i></div>
                </div>
            </div>
            
        </div>

    </div>
</template>

<script>
    import banner from '../../../components/banner.vue';
    import {getplates,getplatexts,getplateAds} from '../../../api/index'
    export default {
        data() {
            return {
                name:'',
                fenleiList:[], 
                tuijianfenlei:[],
                liebiaoList:[],
                tuijianliebiao:[]  ,
                gg:[]          
            }
        },
        components:{
            banner
        },
        created() {
            this.getName()
            this.getplates();
            // this.getplatexts()
            this.gettuijian()
            this.getTuijianplates()
            this.getplateAds()
        },
        methods: {
             //获取分类名字
            getName(){
                getplates({
                    p_id:1
                }).then((res)=>{
                    // console.log(res)
                    if (res.code==200) {
                        // this.fuwuList=res.data
                        for (let i = 0; i < res.data.length; i++) {
                             if (this.$route.query.hzy_id==res.data[i].id) {
                                 this.name=res.data[i].name
                                 return;
                             }
                        }
                    }
                })
            },
            // 广告
            getplateAds(){
                getplateAds({
                    plate_id:5,
                    seat:0
                }).then((res)=>{
                    // console.log(res)
                    if(res.code==200){
                        this.gg=res.data
                    }
                })
            },
            // 去除富文本所有标签
            delLabel(input){
                if (input!=null) {
                    return input.replace(/<\/?[^>]*>/g, ""); 
                }
            },
            // 跳转页面
            goTo(url,query) {
                this.$router.push({ 
                    path: url ,
                    query
                });
            },
            //获取分类
            getplates(){
                getplates({
                    p_id:5
                }).then((res)=>{
                    // console.log(res)
                    if (res.code==200) {
                        this.fenleiList=res.data
                    }
                })
            },            
            //获取推荐列表
            gettuijian(){
                getplatexts({
                    plate_id:5,
                    recom:1
                }).then((res)=>{
                    // console.log(res)
                    if (res.code==200) {
                        this.tuijianliebiao=res.data.data;
                        // nextTick 事件延时
                        this.$nextTick(()=>{
                            new Swiper(this.$refs['xinxibanner'], {
                                slidesPerView: 4,
                                paginationClickable: true,
                                spaceBetween: 30,
                                nextButton: this.$refs['swiper-button-next'],
                                prevButton: this.$refs['swiper-button-prev'],
                            })  
                        })
                    }
                })
            },


            //获取推荐分类
            getTuijianplates(){
                getplates({
                    p_id:5,
                    recom:1
                }).then((res)=>{
                    // console.log(res)
                    if (res.code==200) {
                        this.tuijianfenlei=res.data.slice(0,4)
                        for (let i = 0; i < this.tuijianfenlei.length; i++) {
                            this.getplatexts(this.tuijianfenlei[i].id)                            
                        }                        
                    }
                })
            },
            //获取列表
            getplatexts(plate_id){
                // console.log(plate_id)
                getplatexts({
                    plate_id
                }).then((res)=>{
                    // console.log(res)
                    if (res.code==200) {
                        this.liebiaoList[plate_id]=res.data.data.slice(0,4);
                        this.$set(this.liebiaoList,plate_id,this.liebiaoList[plate_id])
                        // console.log(this.liebiaoList)
                    }
                })

            },
            
        },
        
    }
</script>

<style lang="less" scoped>
    .page{overflow: hidden;}
    .tuijian-wrap{
        margin: auto;
        .tuijian-list{
            margin-top: 40px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            .tuijian-item{
                width: 18%;
                margin-right: 2.5%;
                padding: 0 10px;
                border: 1px solid #E8E8E8;
                background: #FFFFFF;
                box-sizing: border-box;
                margin-bottom: 15px;
                .tuijian-top{
                    padding: 30px 0 0 0;
                    text-align: center;
                    .tuijian-text{
                        .tuijian-p{color: #fff;font-size: 24px;font-weight: bold;margin-bottom: 6px;overflow: hidden; white-space: nowrap;text-overflow: ellipsis;}
                        .tuijian-p1{color: #fff;font-size: 16px;font-weight: 400;overflow: hidden; white-space: nowrap;text-overflow: ellipsis;}
                    }
                }
                .tuijian-bottom{
                    padding: 20px 0;
                    display: flex;justify-content: center;
                    .tuijian-bt{width: 55%;height: 30px;line-height: 30px;text-align: center;background: #fff;border-radius: 14px;
                        color: #666666;font-size: 14px;font-weight: 400;border-radius: 15px;cursor: pointer;
                        &:hover{color: #F15012;}
                    }
                }
                &:nth-child(5n+0){margin-right: 0;background: url('../../../assets/images/jksctj5.png');}
                &:nth-child(1){background: url('../../../assets/images/jksctj1.png');}
                &:nth-child(5n+1){background: url('../../../assets/images/jksctj1.png');}
                &:nth-child(2){background: url('../../../assets/images/jksctj2.png');}
                &:nth-child(5n+2){background: url('../../../assets/images/jksctj2.png');}
                &:nth-child(3){background: url('../../../assets/images/jksctj3.png');}
                &:nth-child(5n+3){background: url('../../../assets/images/jksctj3.png');}
                &:nth-child(4){background: url('../../../assets/images/jksctj4.png');}
                &:nth-child(5n+4){background: url('../../../assets/images/jksctj4.png');}
            }
        }

        .pingtai{
            .pingtai-text{
                margin: 75px auto;text-align:center;
                .pingtai-p{color: #333333;font-size: 35px;}
                .pingtai-p1{color:#666666;font-size: 16px;font-weight: 300;margin-top: 15px;}
            }
            .pingtai-list{
                display: flex;justify-content: space-between;
                .pingtai-item{
                    width: 17%;box-sizing: border-box;text-align: center;margin-bottom:75px;
                    img{width: 67px;height: 67px;margin: auto;}
                    .pingtai-item-p{color: #000000;font-size: 18px;margin-top: 20px;}
                }
            }
        }

    }
    .zbcgbg{
        width: 100%;height: auto;
        img{width:100%;}    
    }

    .xinxi-wrap{
        margin: auto;
        .xinxi-banner{
            position: relative;
            .xinxi-img{width:100%;height:150px;
                img{width: 100%;height: 100%;object-fit: cover;}
            }
            .xinxi-text{color: #2E2E2E;font-size: 20px;font-weight: bold;padding: 17px 20px;overflow: hidden; white-space: nowrap;text-overflow: ellipsis;}
            .xinxi-p{height:78px;color: #666666;font-size: 15px;font-weight: 300;padding: 0 20px;display: -webkit-box;
                -webkit-box-orient: vertical;-webkit-line-clamp: 3;overflow: hidden;line-height: 26px;}
            .xinxi-bt{
                width: 80%;height: 45px;line-height: 45px;text-align: center;color: #fff;font-size: 16px;cursor: pointer;
                background: linear-gradient(90deg, #FF820C, #FFB053);border-radius: 22px;margin: auto;margin-top: 25px;
            }
            
        }
        .xinxi-banner .swiper-container{width: 100%;height: 380px;overflow: hidden;padding:45px 0 80px 0;}
        .xinxi-banner .swiper-button-prev, .xinxi-banner  .swiper-button-next{width: 11px;height: 20px;background-size: 11px 20px;}
        .xinxi-banner .swiper-button-prev{left: -40px !important;}
        .xinxi-banner .swiper-button-next{right: -40px !important;}
        .xinxi-banner .swiper-slide {box-shadow: 0px 4px 9px 1px rgba(0, 0, 0, 0.06);border-radius: 5px;}
        
    }

    .mokuai-wrap{
        margin: auto;
        padding-bottom: 80px;
        overflow: hidden;
        .mokuai-list{
            margin:45px;
            display: flex;
            .mokuai-item{
                width: 23%;
                margin-right: 2.6666%;
                border: 1px solid #E9E9E9;
                box-sizing: border-box;
                .mokuai-text{padding: 15px;border-bottom: 1px solid #e9e9e9;}
                .mokuai-p{
                    display: flex;
                    align-items: center;
                    color: #666666;
                    font-size: 15px;
                    margin: 20px 15px;                    
                    p{
                        background: #0071DB;
                        color: #fff;
                        padding: 4px 6px;
                        margin-right: 5px;
                    }
                    span{
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;

                    }
                }
                .more{
                    padding: 20px;
                    padding-top: 10px;
                    text-align: right;
                    color: #929191;
                    font-size: 14px;
                    cursor: pointer;
                }
                &:last-of-type{margin-right: 0;}
            }
        }
    }
</style>