import { render, staticRenderFns } from "./zbcg.vue?vue&type=template&id=83344cba&scoped=true&"
import script from "./zbcg.vue?vue&type=script&lang=js&"
export * from "./zbcg.vue?vue&type=script&lang=js&"
import style0 from "./zbcg.vue?vue&type=style&index=0&id=83344cba&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "83344cba",
  null
  
)

export default component.exports